import * as React from "react";

export default function HeaderComponent() {
  return (
    <div>
      <header role="banner" id="fh5co-header">
        <div className="container">
          <nav className="navbar navbar-default">
            <div className="navbar-header">
              <a
                href="/"
                className="js-fh5co-nav-toggle fh5co-nav-toggle"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <i></i>
              </a>
              <a className="navbar-brand" href="/">
                ACS
              </a>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="https://blog.atithi.live" class="external">
                    <span>Blog</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
