import * as React from "react";
import { useParams } from "react-router-dom";
import fullimage2 from "../images/full_image_2.jpg";
import { Blogs } from "./Blogs";
function IBlogComponent() {
  let id = useParams();
  console.log(id.id);
  const blog = Blogs[id.id - 1];

  return (
    <div>
      <section
        id="fh5co-home"
        data-section="home"
        style={{ backgroundImage: { fullimage2 }, height: `550px` }}
        data-stellar-background-ratio="0.5"
      >
        <div className="gradient"></div>
        <div className="container">
          <div className="text-wrap">
            <div className="text-inner" style={{ paddingBottom: `400px` }}>
              <h1 className="to-animate">{blog.title}</h1>
            </div>
          </div>
        </div>
        <div className="slant"></div>
      </section>
      <section id="fh5co-intro">
        <div className="container" >
          <div
            className="row row-bottom-padded-lg"
            style={{
              backgroundColor: "white",
              padding: "30px",
              boxShadow: "5px 5px 20px 8px lightgrey",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <img src={blog.image} alt={blog.title} className="img-responsive" />
            <div className="col-12">
              <div>
                <h2 style={{ marginTop: `10px`, fontSize: `40px` }}>
                  {blog.title}
                </h2>
                <span>
                  {blog.content.map((para) => {
                    return (
                      <div
                        style={{
                          marginBottom: `10px`,
                          fontWeight: 600,
                          color: "black",
                        }}
                      >
                        {para}
                      </div>
                    );
                  })}
                </span>
                <br />
                <div style={{ fontSize: `15px` }}>By {blog.name}</div>
                <div style={{ fontSize: `15px` }}>Date : {blog.date}</div>
                <div style={{ fontSize: `15px` }}>Tags: {blog.tags}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default IBlogComponent;
