import * as React from "react";
import fullimage from "../images/full_image.jpg";
import { Link } from "react-router-dom";
import { Blogs } from "./Blogs";
function BlogComponent() {
  const user = Blogs.map((item) => {
    return (
      <div className="col-md-4 col-sm-6 col-xxs-12" style={{ padding: `10px` }}>
        <Link to={"/blog/" + item.id} className="external">
          <img
            src={item.image}
            alt="blog3"
            className="img-responsive"
            style={{ marginBottom: `10px` }}
          />
        </Link>
        <div>
          <h2>{item.title}</h2>
          <span style={{ fontSize: `0.9em` }}>{item.desc}</span>
        </div>
      </div>
    );
  });
  return (
    <div>
      <section
        id="fh5co-home"
        data-section="home"
        style={{ backgroundImage: { fullimage }, height: `550px` }}
        data-stellar-background-ratio="0.5"
      >
        <div className="gradient"></div>
        <div className="container">
          <div className="text-wrap">
            <div className="text-inner" style={{ paddingBottom: `400px` }}>
              <h1 className="to-animate">Atithi Compliance System </h1>
            </div>
          </div>
        </div>
        <div className="slant"></div>
      </section>
      <section id="fh5co-intro">
        <div className="container">
          <div
            className="row row-bottom-padded-lg"
            style={{
              backgroundColor: "white",
              padding: "10px",
              boxShadow: "5px 5px 20px 8px lightgrey",
            }}
          >
            {user}
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogComponent;
