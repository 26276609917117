import * as React from "react";
import {
  FaArrowUp,
  FaDiscord,
  FaLinkedin,
  FaTwitterSquare,
  FaGithub,
} from "react-icons/fa";
import { GrMail } from "react-icons/gr";

import fImg1 from "../images/fImg1.png";
import fImg2 from "../images/fImg2.png";
export default function FooterComponent() {
  return (
    <div>
      <section
        id="fh5co-contact"
        data-section="contact"
        style={{ borderTop: "2px solid #adabab" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4 to-animate">
              <h3>Contact</h3>
              <div className="py-4">36Inc Incubation Centre, Pandri</div>
              <div className="py-4">City Centre Mall, Raipur</div>
              <div className="py-4">Chhattisgarh, India</div>
              <div className="py-4">+91 877 046 7824</div>
              <div className="py-4">info@atithi.live</div>
              <br />
              <div>
                <a href="mailto:info@atithi.live">
                  <GrMail
                    style={{
                      color: `#c24d67`,
                      fontSize: "30px",
                      marginRight: "20px",
                    }}
                  />
                </a>
                <a href="https://www.linkedin.com/company/atithiapp/">
                  <FaLinkedin
                    style={{
                      color: `#c24d67`,
                      fontSize: "30px",
                      marginRight: "20px",
                    }}
                  />
                </a>
                <a href="https://discord.gg/PTAF3JXajY">
                  <FaDiscord
                    style={{
                      color: `#c24d67`,
                      fontSize: "30px",
                      marginRight: "20px",
                    }}
                  />
                </a>
                <a href="https://twitter.com/atithilive">
                  <FaTwitterSquare
                    style={{
                      color: `#c24d67`,
                      fontSize: "30px",
                      marginRight: "20px",
                    }}
                  />
                </a>
                <a href="https://github.com/atithilive">
                  <FaGithub
                    style={{
                      color: `#c24d67`,
                      fontSize: "30px",
                      marginRight: "20px",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-2">
              <h3>Supported by</h3>
              <img src={fImg1} alt="img2" style={{ width: `200px` }} />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-2 ">
              <h3>Incubated by</h3>
              <img src={fImg2} alt="img1" style={{ width: `200px` }} />
            </div>
          </div>
        </div>
      </section>
      <footer id="footer" role="contentinfo">
        <a href="/" className="gotop js-gotop">
          <FaArrowUp />
        </a>
        <p className="text-center">
          &copy; 2022 - Aithi Compliance System. All Rights Reserved.
        </p>
      </footer>
    </div>
  );
}
