import privacy from "../images/privacy_acs.jpg";
import distributed from "../images/distributed_acs.jpg";
import audit from "../images/audit_acs.jpg";
export const Blogs = [
  {
    id: 1,
    image: privacy,
    title: "Duty of Aithi Compliance for Hospitality Industry",
    desc: "We never publish raw data on the Blockchain. We use unique cryptographic identifiers in order not to reveal the original data. Furthermore, we have no access to original files.",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
    ],
    name: "Shauryaditya Singh",
    date: "22/10/2022",
    tags: "Hotels, Compliance, India, Law Enforcemen",
  },
  {
    id: 2,
    image: distributed,
    title: "Enviromental Impact of your KYC at Hotel",
    desc: "We never publish raw data on the Blockchain. We use unique cryptographic identifiers in order not to reveal the original data. Furthermore, we have no access to original files.",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
    ],
    name: "Gaurav Mehta",
    date: "22/10/2022",
    tags: "Enviroment, Hotel, KYC, Check-in, Atithi",
  },
  {
    id: 3,
    image: audit,
    title: "On mission of paperless & effective compliance",
    desc: "Since Blockchain are accessible, the cryptographic proofs that we generate are everything need to provide or verify that event happened while maintaining absolute privacy.",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto eum in asperiores nam explicabo, fuga rerum, repellat eveniet tempora delectus sint, quia corporis fugiat animi consequuntur vero enim? Numquam nobis, commodi consequuntur similique distinctio exercitationem ex iure eos explicabo animi vero impedit cupiditate non dignissimos iusto atque culpa quae beatae.",
    ],
    name: "Gaurav Mehta",
    date: "22/10/2022",
    tags: "Blockchain, Privacy, Compliance, India",
  },
];
